import {createContext, useCallback, useDeferredValue, useEffect, useRef, useState} from "react"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"

import Settings from "./pages/settings"
import Dashboard from "./pages/dashboard"
import SettingsAWS from "./pages/settings/aws"
import SettingsTokens from "./pages/settings/tokens"
import SettingsCompetitors from "./pages/settings/competitors"
import SettingsBridges from "./pages/settings/bridges"
import SettingsConfigs from "./pages/settings/configs"
import Transactions from "./pages/transactions"
import LatestTransactions from "./pages/transactions/latest"
import ArchivedTransactions from "./pages/transactions/archived"
import NewTransaction from "./pages/transactions/new"

import {
    AWSGroupScheme,
    BRAIN_EVENTS,
    DBAutomationRules,
    DBBridges,
    DBMiscSettings,
    DBTokens,
    DEFAULT_BRAIN_STATE,
    GO_NODER_EVENTS,
    MODULE_EVENTS,
    MODULE_EVENTS_PARAMS,
    NETWORK,
    NODER_EVENTS,
    SOCKET_CLIENT_RSA_KEYS,
    SOCKET_PATH,
    Trades,
    WORKER_EVENTS
} from "./common"
import {useImmer} from "use-immer"
import Loader from "./components/Loader"

import {useSetEventHandlers} from "./helper/EventHandlers"
import SecureLocalStorage from "./helper/SecureLocalStorage"
import {ethers} from "ethers"
import {toast, ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SocketClient, {
    exportPKCS8PrivateKeyPem,
    exportSPKIPublicKeyPem,
    importPKCS8PrivateKeyPem,
    importSPKIPublicKeyPem,
    INTERFACE_SOCKET_CLIENT_RSA_KEYS,
} from "./common_custom/SocketClient"
import Logger from "./common_custom/Logger"
import Sound from "./helper/Sound"
import {Competitors} from "./common/types/monitoringTypes"

/**
 * Sends a data update event
 */
export function sendUpdateEvent<T extends MODULE_EVENTS>(eventName: T, eventData: MODULE_EVENTS_PARAMS[T]) {
    if (process.env.NODE_ENV === "production") {
        SocketClient.emitEvent(eventName, eventData)
    } else {
        SocketClient.emitEvent(eventName, eventData)
        // InnerEmitter.emit(eventName, eventData)
    }
}

export type AppContextType = {
    projectName: AppState["projectName"]
    devMode: AppState["devMode"]
    brainState: AppState["brainState"]
    connectedServices: AppState["connectedServices"]
    newHeads: AppState["newHeads"]
    goNoderNewHeads: AppState["goNoderNewHeads"]
    eventerStatsString: AppState["eventerStatsString"]
    workerStatsString: AppState["workerStatsString"]
    workerAnalyticsString: AppState["workerAnalyticsString"]
    workerEventsString: AppState["workerEventsString"]
}
export const AppContext = createContext<AppContextType>({
    projectName: "DefiTracker 4",
    devMode: false,
    brainState: DEFAULT_BRAIN_STATE,
    connectedServices: {},
    newHeads: {},
    goNoderNewHeads: {},
    eventerStatsString: "",
    workerStatsString: "",
    workerAnalyticsString: "",
    workerEventsString: "",
})

export type AppExpiryContextType = {
    timeValueBeforeDisconnect: AppState["timeValueBeforeDisconnect"]
    disconnectButtonVariant: AppState["disconnectButtonVariant"]
}
export const AppExpiryContext = createContext<AppExpiryContextType>({
    timeValueBeforeDisconnect: "59:59",
    disconnectButtonVariant: "light",
})

export type AppState = {
    projectName: string
    devMode: boolean
    msRemainingBeforeDisconnect: number
    timeValueBeforeDisconnect: string
    disconnectButtonVariant: "light" | "warning" | "danger"
    brainState: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.BRAIN_STATE_UPDATE]
    connectedServices: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_SERVICES]

    newHeads: { [key in NETWORK]?: MODULE_EVENTS_PARAMS[NODER_EVENTS.NEW_HEADS] }
    goNoderNewHeads: { [key in NETWORK]?: MODULE_EVENTS_PARAMS[GO_NODER_EVENTS.NEW_BLOCK_TX_DATAS] }
    gasEstimate: { [key in NETWORK]?: MODULE_EVENTS_PARAMS[NODER_EVENTS.GAS_ESTIMATE] }
    balance: {
        [networkName in NETWORK]?: {
            [tokenName: string]: string
        }
    }

    allTokens: DBTokens
    // newTokens: UnknownTokens
    competitors: Competitors
    archivedTxsFileNames: string[]
    currentArchiveFileName: string | undefined
    archivedTxs: { [key: string]: string }
    bridges: DBBridges
    trades: Trades
    automationRules?: DBAutomationRules
    awsGroups: AWSGroupScheme[]
    trackingResults: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_RESULT]
    eventerStatsString: string
    workerStatsString: string
    workerAnalyticsString: string
    workerEventsString: string

    miscSettings?: DBMiscSettings
    connectedUsers: MODULE_EVENTS_PARAMS[BRAIN_EVENTS.CONNECTED_USERS]

    selectedAccount: {
        address: string
        privateKey: string
    }

    configs?: {
        transactions: string
    }
}

export default function App() {
    const [appState, setAppState] = useImmer<AppState>({
        projectName: "DefiTracker 4",
        devMode: false,
        msRemainingBeforeDisconnect: 60 * 60 * 1000 - 1000,
        timeValueBeforeDisconnect: "59:59",
        disconnectButtonVariant: "light",
        brainState: DEFAULT_BRAIN_STATE,
        connectedServices: {},

        newHeads: {
            // [NETWORK.ETHEREUM]: {
            // 	networkName: NETWORK.ETHEREUM,
            // 	blockNumber: 17862276,
            // },
            // [NETWORK.BINANCE]: {
            // 	networkName: NETWORK.BINANCE,
            // 	blockNumber: 30641474,
            // },
            // [NETWORK.POLYGON]: {
            // 	networkName: NETWORK.POLYGON,
            // 	blockNumber: 30641474,
            // },
            // [NETWORK.ARBITRUM]: {
            // 	networkName: NETWORK.ARBITRUM,
            // 	blockNumber: 30641474,
            // },
            // [NETWORK.OPTIMISM]: {
            // 	networkName: NETWORK.OPTIMISM,
            // 	blockNumber: 30641474,
            // },
        },
        goNoderNewHeads: {},
        gasEstimate: {},
        balance: {
            // [NETWORK.ETHEREUM]: {
            // 	[NETWORK_NATIVE_TOKEN[NETWORK.ETHEREUM]]: 0.03,
            // 	[NETWORK_SOURCE_TOKEN[NETWORK.ETHEREUM][0]]: 14.454390341,
            // },
            // [NETWORK.BINANCE]: {
            // 	[NETWORK_NATIVE_TOKEN[NETWORK.BINANCE]]: 0.042344,
            // 	[NETWORK_SOURCE_TOKEN[NETWORK.BINANCE][0]]: 6.72425,
            // },
            // [NETWORK.POLYGON]: {
            // 	[NETWORK_NATIVE_TOKEN[NETWORK.POLYGON]]: 3.33445622,
            // 	[NETWORK_SOURCE_TOKEN[NETWORK.POLYGON][0]]: 994.34242424,
            // },
        },

        allTokens: {},
        competitors: {},
        archivedTxsFileNames: [],
        currentArchiveFileName: undefined,
        archivedTxs: {},
        bridges: {},
        trades: {},
        automationRules: undefined,
        awsGroups: [],
        trackingResults: {},
        eventerStatsString: "",
        workerStatsString: "",
        workerAnalyticsString: "",
        workerEventsString: "",

        miscSettings: undefined,
        connectedUsers: {},

        selectedAccount: {
            address: "",
            privateKey: "",
        },
    })

    const deferredTrackingResults = useDeferredValue(appState.trackingResults)

    const dataToastId = useRef<ReturnType<typeof toast>>("")
    const toastDataUpdate = useCallback((text: string) => {
        // toast.dismiss(dataToastId.current)
        dataToastId.current = toast.success(text)
    }, [])

    // Set event handlers
    useSetEventHandlers(setAppState, toastDataUpdate)

    useEffect(() => {
        Logger.init({
            projectName: "interface2",
        })
    }, [])

    useEffect(() => {
        // setTimeout(() => {
        // 	setAppState(appState => {
        // 		appState.brainState.pinnedTokens = {
        // 			'WBTC': Date.now()
        // 		}
        // 	})
        // }, 3000)
        // setInterval(() => {
        // 	setAppState(appState => {
        // 		appState.newHeads.Binance!.blockNumber += 1
        // 	})
        // }, 2000)
        // setAppState(appState => {
        // 	appState.trackingResults = {
        // 		"WBTC_Binance_Ethereum": {
        // 			"tokenName": "WBTC",
        // 			"trackingValue": "1",
        // 			"profit": "-0.00104945442078696",
        // 			"networkResults": {
        // 				"Ethereum": {
        // 					"blockNumber": 17862276,
        // 					"route": {
        // 						"networkName": NETWORK.ETHEREUM,
        // 						"dexName": "Uniswap",
        // 						"contractVersion": 3,
        // 						"tokenNamePair": "WETH_WBTC",
        // 						"path": [
        // 							["WETH", "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18],
        // 							["WBTC", "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599", 8]
        // 						],
        // 						"fees": [500]
        // 					},
        // 					"returnAmount": "6309967",
        // 					"returnAmountShifted": "0.06309967"
        // 				},
        // 				"Binance": {
        // 					"blockNumber": 30641474,
        // 					"route": {
        // 						"networkName": NETWORK.BINANCE,
        // 						"dexName": "Pancakeswap",
        // 						"contractVersion": 3,
        // 						"tokenNamePair": "WETH_WBTC",
        // 						"path": [
        // 							["WETH", "0x2170Ed0880ac9A755fd29B2688956BD959F933F8", 18],
        // 							["WBNB", "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", 18],
        // 							["WBTC", "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c", 18]
        // 						],
        // 						"fees": [500, 500]
        // 					},
        // 					"returnAmount": "63019734663669775",
        // 					"returnAmountShifted": "0.063019734663669775"
        // 				}
        // 			},
        // 			"reverse": {
        // 				"blockNumber": 30641474,
        // 				"route": {
        // 					"networkName": NETWORK.BINANCE,
        // 					"dexName": "Pancakeswap",
        // 					"contractVersion": 3,
        // 					"tokenNamePair": "WBTC_WETH",
        // 					"path": [
        // 						["WBTC", "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c", 18],
        // 						["WBNB", "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", 18],
        // 						["WETH", "0x2170Ed0880ac9A755fd29B2688956BD959F933F8", 18]
        // 					],
        // 					"fees": [500, 500]
        // 				},
        // 				"returnAmount": "998950545579213040",
        // 				"returnAmountShifted": "0.99895054557921304"
        // 			}
        // 		},
        // 		"WBTC_Binance_Polygon": {
        // 			"tokenName": "WBTC",
        // 			"trackingValue": "1",
        // 			"profit": "-0.001177351140540715",
        // 			"networkResults": {
        // 				"Polygon": {
        // 					"blockNumber": 46013827,
        // 					"route": {
        // 						"networkName": NETWORK.POLYGON,
        // 						"dexName": "Uniswap",
        // 						"contractVersion": 3,
        // 						"tokenNamePair": "WETH_WBTC",
        // 						"path": [
        // 							["WETH", "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", 18],
        // 							["WBTC", "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6", 8]
        // 						],
        // 						"fees": [500]
        // 					},
        // 					"returnAmount": "6309159",
        // 					"returnAmountShifted": "0.06309159"
        // 				},
        // 				"Binance": {
        // 					"blockNumber": 30641474,
        // 					"route": {
        // 						"networkName": NETWORK.BINANCE,
        // 						"dexName": "Pancakeswap",
        // 						"contractVersion": 3,
        // 						"tokenNamePair": "WETH_WBTC",
        // 						"path": [
        // 							["WETH", "0x2170Ed0880ac9A755fd29B2688956BD959F933F8", 18],
        // 							["WBNB", "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", 18],
        // 							["WBTC", "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c", 18]
        // 						],
        // 						"fees": [500, 500]
        // 					},
        // 					"returnAmount": "63019734663669775",
        // 					"returnAmountShifted": "0.063019734663669775"
        // 				}
        // 			},
        // 			"reverse": {
        // 				"blockNumber": 30641475,
        // 				"route": {
        // 					"networkName": NETWORK.BINANCE,
        // 					"dexName": "Pancakeswap",
        // 					"contractVersion": 3,
        // 					"tokenNamePair": "WBTC_WETH",
        // 					"path": [
        // 						["WBTC", "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c", 18],
        // 						["WBNB", "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", 18],
        // 						["WETH", "0x2170Ed0880ac9A755fd29B2688956BD959F933F8", 18]
        // 					],
        // 					"fees": [500, 500]
        // 				},
        // 				"returnAmount": "998822648859459285",
        // 				"returnAmountShifted": "0.998822648859459285"
        // 			}
        // 		},
        // 		"WBTC_Ethereum_Polygon": {
        // 			"tokenName": "WBTC",
        // 			"trackingValue": "1",
        // 			"profit": "-0.000903377275565905",
        // 			"networkResults": {
        // 				"Ethereum": {
        // 					"blockNumber": 17862276,
        // 					"route": {
        // 						"networkName": NETWORK.ETHEREUM,
        // 						"dexName": "Uniswap",
        // 						"contractVersion": 3,
        // 						"tokenNamePair": "WETH_WBTC",
        // 						"path": [
        // 							["WETH", "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18],
        // 							["WBTC", "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599", 8]
        // 						],
        // 						"fees": [500]
        // 					},
        // 					"returnAmount": "6309967",
        // 					"returnAmountShifted": "0.06309967"
        // 				},
        // 				"Polygon": {
        // 					"blockNumber": 46013827,
        // 					"route": {
        // 						"networkName": NETWORK.POLYGON,
        // 						"dexName": "Uniswap",
        // 						"contractVersion": 3,
        // 						"tokenNamePair": "WETH_WBTC",
        // 						"path": [
        // 							["WETH", "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", 18],
        // 							["WBTC", "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6", 8]
        // 						],
        // 						"fees": [500]
        // 					},
        // 					"returnAmount": "6309159",
        // 					"returnAmountShifted": "0.06309159"
        // 				}
        // 			},
        // 			"reverse": {
        // 				"blockNumber": 46013827,
        // 				"route": {
        // 					"networkName": NETWORK.POLYGON,
        // 					"dexName": "Uniswap",
        // 					"contractVersion": 3,
        // 					"tokenNamePair": "WBTC_WETH",
        // 					"path": [
        // 						["WBTC", "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6", 8],
        // 						["WETH", "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", 18]
        // 					],
        // 					"fees": [500]
        // 				},
        // 				"returnAmount": "999096622724434095",
        // 				"returnAmountShifted": "0.999096622724434095"
        // 			}
        // 		}
        // 	}
        // })
    }, [setAppState])

    useEffect(() => {
        // @ts-ignore
        window.notifyAll = (msg: string, autoClose?: number | false) => {
            SocketClient.emitEvent(BRAIN_EVENTS.NOTIFICATION_TO_CLIENT, {msg, type: "info", autoClose})
        }
        // @ts-ignore
        window.sendCommand = (to: string, cmd: string) => {
            SocketClient.emitEvent(MODULE_EVENTS.COMMAND, {to, cmd})
        }
    }, [])

    useEffect(() => {
        let interval: NodeJS.Timer

        if (appState.msRemainingBeforeDisconnect > 0) {
            console.log("Settings up ping to keep alive interval")

            interval = setInterval(() => {
                fetch(window.location.origin)
                    .then(() => {
                        console.log("🟢 ping to keep alive success")
                    })
                    .catch((e) => {
                        console.warn("🟠 ping to keep alive error", e)
                    })
            }, 1000 * 60)
        }

        return () => {
            clearInterval(interval)
            console.log("Cleared ping to keep alive interval")
        }
    }, [appState.msRemainingBeforeDisconnect])

    useEffect(() => {
        let dateWhenExpires = new Date(Date.now() + appState.msRemainingBeforeDisconnect)

        const interval = setInterval(() => {
            let msRemaining = dateWhenExpires.getTime() - Date.now()
            let minutes = Math.floor(msRemaining / 1000 / 60)
            let seconds = Math.floor((msRemaining / 1000) % 60)

            if (minutes < 0) minutes = 0
            if (seconds < 0) seconds = 0

            setAppState((draft) => {
                draft.timeValueBeforeDisconnect = `${String(minutes).padStart(2, "0")}:${String(
                    seconds
                ).padStart(2, "0")}`

                if (minutes < 5) {
                    draft.disconnectButtonVariant = "danger"
                } else if (minutes < 10) {
                    draft.disconnectButtonVariant = "warning"
                } else {
                    draft.disconnectButtonVariant = "light"
                }
            })
        }, 1000)

        return () => clearInterval(interval)
    }, [appState.msRemainingBeforeDisconnect])

    /**
     * Load private key from local storage
     */
    useEffect(() => {
        const privateKey = SecureLocalStorage.getItem("WALLET_PRIVATE_KEY")
        if (!privateKey) return

        try {
            const wallet = new ethers.Wallet(privateKey)
            setAppState((draft) => {
                draft.selectedAccount = {
                    address: wallet.address,
                    privateKey: wallet.privateKey,
                }
            })
        } catch (e) {
        }
    }, [])

    /**
     * Generate key pair
     */
        // const [keyPair, setKeyPair] = useState<ENCRYPTION_WORKER_RESULT["GENERATE_KEYS"]>()
    const [keyPair, setKeyPair] = useState<INTERFACE_SOCKET_CLIENT_RSA_KEYS>()
    const keyGenStartedRef = useRef<boolean>(false)
    useEffect(() => {
        if (keyGenStartedRef.current) return
        keyGenStartedRef.current = true

        // const keysPromise = SocketClient.generateKeyPairSync()

        const keysPromise = new Promise<INTERFACE_SOCKET_CLIENT_RSA_KEYS>(async (resolve) => {
            await new Promise((r) => setTimeout(r, 100))

            if (window.crypto.subtle === undefined) {
                return toast.error(
                    "Unable to generate RSA keys. window.crypto.subtle is undefined. Secure https context required.",
                    {
                        autoClose: false,
                        closeButton: false,
                        closeOnClick: false,
                    }
                )
            }

            // Try to load keys locally
            const localKeys = SecureLocalStorage.getItem("RSA_KEY_PAIR")
            if (localKeys) {
                try {
                    const keys: INTERFACE_SOCKET_CLIENT_RSA_KEYS = {
                        keySize: localKeys.keySize,
                        time: localKeys.time,
                        public: await importSPKIPublicKeyPem(localKeys.public),
                        private: await importPKCS8PrivateKeyPem(localKeys.private),
                    }
                    console.log("Loaded rsa keys from localStorage")
                    return resolve(keys)
                } catch (e) {
                    SecureLocalStorage.removeItem("RSA_KEY_PAIR")
                }
            }

            // Generate new keys
            const keys = await SocketClient.generateKeyPairSync()
            const localKeysToSave: SOCKET_CLIENT_RSA_KEYS = {
                keySize: keys.keySize,
                time: keys.time,
                public: await exportSPKIPublicKeyPem(keys.public),
                private: await exportPKCS8PrivateKeyPem(keys.private),
            }
            SecureLocalStorage.setItem("RSA_KEY_PAIR", localKeysToSave)
            console.log(`Generated rsa keys in ${keys.time}ms`)
            return resolve(keys)
        })

        toast
            .promise(
                keysPromise,
                {
                    pending: "Generating RSA key pair...",
                    success: "RSA key pair generated",
                    error: "Failed to generate RSA key pair",
                },
                {
                    autoClose: 1000,
                    closeOnClick: false,
                    closeButton: false,
                }
            )
            .then((keys) => {
                SocketClient.init({
                    baseUrl: process.env.REACT_APP_SOCKET_BASE_URL || "http://localhost:3000",
                    socketPath: SOCKET_PATH.INTERFACE,
                    keyPair: keys,
                })
                setKeyPair(keys)
            })
            .catch((e) => {
            })
    }, [])

    /**
     * Auth / ReAuth handler
     */
    const authToastId = useRef<ReturnType<typeof toast>>("")
    useEffect(() => {
        if (!keyPair) return

        toast.dismiss(authToastId.current)
        if (!appState.selectedAccount.address) {
            authToastId.current = toast.info("Please select an account to authenticate", {
                autoClose: false,
                closeOnClick: false,
                closeButton: false,
            })
        } else {
            const authPromise = Promise.all([
                SocketClient.connect(
                    [
                        MODULE_EVENTS.INIT_INTERFACE,
                        MODULE_EVENTS.INTERFACE_EVENT_CACHE,

                        MODULE_EVENTS.BRAIN_STATE_UPDATE,
                        MODULE_EVENTS.AWS_GROUPS_UPDATE_PROCESSED,
                        MODULE_EVENTS.CONNECTED_USERS,
                        MODULE_EVENTS.CONNECTED_SERVICES,
                        MODULE_EVENTS.UPDATE_MISC_SETTINGS_PROCESSED,
                        MODULE_EVENTS.CREATE_TOKEN_PROCESSED,
                        MODULE_EVENTS.UPDATE_TOKEN_PROCESSED,
                        MODULE_EVENTS.DELETE_TOKEN_PROCESSED,
                        MODULE_EVENTS.CREATE_BRIDGE_PROCESSED,
                        MODULE_EVENTS.UPDATE_BRIDGE_PROCESSED,
                        MODULE_EVENTS.DELETE_BRIDGE_PROCESSED,
                        MODULE_EVENTS.UPDATE_AUTOMATION_RULES_PROCESSED,
                        // MODULE_EVENTS.CREATE_AUTOMATION_MIN_COEFF_RULE_PROCESSED,
                        // MODULE_EVENTS.UPDATE_AUTOMATION_MIN_COEFF_RULE_PROCESSED,
                        // MODULE_EVENTS.DELETE_AUTOMATION_MIN_COEFF_RULE_PROCESSED,
                        // MODULE_EVENTS.CREATE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED,
                        // MODULE_EVENTS.UPDATE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED,
                        // MODULE_EVENTS.DELETE_AUTOMATION_TOKEN_BRIDGE_RULE_PROCESSED,

                        MODULE_EVENTS.NOTIFICATION_TO_CLIENT,

                        MODULE_EVENTS.NEW_HEADS,
                        MODULE_EVENTS.GAS_ESTIMATE,
                        MODULE_EVENTS.BALANCE_UPDATE,

                        MODULE_EVENTS.NEW_BLOCK_TX_DATAS,
                        MODULE_EVENTS.BLOCK_TX_EXTRACT_ERROR,

                        MODULE_EVENTS.EVENTER_STATS_STRING,

                        MODULE_EVENTS.WORKER_STATS_STRING,
                        MODULE_EVENTS.WORKER_ANALYTICS_STRING,
                        MODULE_EVENTS.WORKER_EVENTS_STRING,
                        MODULE_EVENTS.WORKER_ITERATION_RESULT,

                        MODULE_EVENTS.TRADE_CREATED,
                        MODULE_EVENTS.TRANSACTIONS_SENT,
                        MODULE_EVENTS.TRANSACTIONS_CREATED,
                        MODULE_EVENTS.TRANSACTIONS_CONFIRMED,
                        MODULE_EVENTS.TRADE_COMPLETED,
                        MODULE_EVENTS.TRANSACTIONS_LIST_ARCHIVED_RESPONSE,
                        MODULE_EVENTS.TRANSACTIONS_LIST_ARCHIVED_ITEM_RESPONSE,

                        MODULE_EVENTS.MONITORING_ON_UPDATE_COMPETITOR_PROCESSED,
                        MODULE_EVENTS.MONITORING_ON_UPDATE_UNKNOWN_TOKEN_PROCESSED,

                        MODULE_EVENTS.FETCH_TXS_CONFIG_RESULT,
                    ],
                    appState.selectedAccount.address + ":" + appState.selectedAccount.privateKey,
                    () => {
                        Sound.play("error")
                        setAppState((draft) => {
                            draft.msRemainingBeforeDisconnect = 0
                        })
                        toast.warn("Disconnected from server. Please reload the page to reconnect", {
                            autoClose: false,
                            closeOnClick: false,
                            closeButton: false,
                        })
                    }
                ).then(() => {
                    Sound.play("plop")
                    setTimeout(() => {
                        Sound.play("plop")
                    }, 200)
                }),
                new Promise((r) => setTimeout(r, 100)),
            ])
            toast
                .promise(
                    authPromise,
                    {
                        pending: "Connecting to server...",
                        success: "Connected successfully",
                        error: "Failed to authenticate",
                    },
                    {
                        autoClose: 1000,
                        closeOnClick: false,
                        closeButton: false,
                    }
                )
                .catch((e) => {
                    toast.error("Failed to authenticate with server", {
                        autoClose: false,
                        closeOnClick: false,
                        closeButton: false,
                    })
                })
        }
    }, [appState.selectedAccount, keyPair, setAppState])

    return (
        <AppContext.Provider
            value={{
                projectName: appState.projectName,
                devMode: appState.devMode,
                brainState: appState.brainState,
                connectedServices: appState.connectedServices,
                newHeads: appState.newHeads,
                goNoderNewHeads: appState.goNoderNewHeads,
                eventerStatsString: appState.eventerStatsString,
                workerStatsString: appState.workerStatsString,
                workerAnalyticsString: appState.workerAnalyticsString,
                workerEventsString: appState.workerEventsString,
            }}
        >
            <AppExpiryContext.Provider
                value={{
                    timeValueBeforeDisconnect: appState.timeValueBeforeDisconnect,
                    disconnectButtonVariant: appState.disconnectButtonVariant,
                }}
            >
                <Loader projectName="DefiTracker 4" buildNumber="0.0.1"/>
                <ToastContainer draggable={false}/>
                <Router>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Dashboard
                                    workersRunning={appState.brainState.workersRunning}
                                    workersStartedBy={appState.brainState.workersStartedBy}
                                    pinnedTokens={appState.brainState.pinnedTokens}
                                    pinnedTokensNoTrade={appState.brainState.pinnedTokensNoTrade}
                                    autosellPins={appState.brainState.autosellPins}
                                    newHeads={appState.newHeads}
                                    allTokens={appState.allTokens}
                                    bridges={appState.bridges}
                                    trackingResults={deferredTrackingResults}
                                    selectedAccount={appState.selectedAccount}
                                    miscSettings={appState.miscSettings}
                                    connectedUsers={appState.connectedUsers}
                                    gasEstimate={appState.gasEstimate}
                                    balance={appState.balance}
                                    setAppState={setAppState}
                                    automationRules={appState.automationRules}
                                />
                            }
                        />

                        <Route path="/tx" element={<Transactions/>}/>

                        <Route
                            path="/tx/latest"
                            element={
                                <LatestTransactions tokens={appState.allTokens} trades={appState.trades}/>
                            }
                        />

                        <Route
                            path="/tx/archived"
                            element={
                                <ArchivedTransactions
                                    tokens={appState.allTokens}
                                    fileList={appState.archivedTxsFileNames}
                                    currentArchiveFileName={appState.currentArchiveFileName || ""}
                                    data={appState.archivedTxs}
                                />
                            }
                        />

                        <Route path="/tx/new" element={<NewTransaction tokens={appState.allTokens}/>}/>

                        <Route path="/settings" element={<Settings/>}/>

                        <Route
                            path="/settings/aws"
                            element={<SettingsAWS awsGroups={appState.awsGroups}/>}
                        />

                        <Route
                            path="/settings/configs"
                            element={
                                <SettingsConfigs
                                    configs={appState.configs}
                                    workersStarted={appState.brainState.workersRunning}
                                    setAppState={setAppState}
                                />
                            }
                        />

                        <Route
                            path="/settings/tokens"
                            element={
                                <SettingsTokens
                                    allTokens={appState.allTokens}
                                    bridges={appState.bridges}
                                    miscSettings={appState.miscSettings}
                                />
                            }
                        />

                        <Route
                            path="/settings/competitors"
                            element={<SettingsCompetitors competitors={appState.competitors}/>}
                        />

                        <Route
                            path="/settings/bridges"
                            element={
                                <SettingsBridges allTokens={appState.allTokens} bridges={appState.bridges}/>
                            }
                        />
                    </Routes>
                </Router>
            </AppExpiryContext.Provider>
        </AppContext.Provider>
    )
}
