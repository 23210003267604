import {memo, useCallback, useMemo} from "react"
import {Badge, Button, Stack} from "react-bootstrap"
import BigNumber from "bignumber.js"

import VirtualTable, {HeaderRow, TableRow} from "../../../components/VirtualTable"
import {
    DBTokens,
    DBTX,
    NETWORK,
    NETWORK_EXPLORER_ADDRESS,
    NETWORK_NATIVE_AND_WRAPPED_TOKENS,
    toMilliseconds,
    Trade,
    Trades,
    TxType
} from "../../../common"
import Popup from "../../../helper/Popup"
import {TransactionsFilterData} from ".."
import {NetworkToTicker} from "../../../helper/misc";
import {ArrowLeft, ArrowRight} from "../../../helper/svg";

const ONE_DAY = 24 * 60 * 60 * 1000; // 24 hours
const SEVEN_DAYS = 7 * ONE_DAY;      // 7 days
const THIRTY_DAYS = 30 * ONE_DAY;    // 30 days

const columns = [
    {name: "Side", key: "side", minWidth: 50},
    {name: "Tx hash", key: "txHash", minWidth: 140, width: "-webkit-fill-available"},
    {name: "Sender", key: "sender", minWidth: 140},
    {name: "Type", key: "type", minWidth: 100},
    {name: "Token", key: "token", minWidth: 100},
    {name: "Network", key: "network", minWidth: 100},
    {name: "To", key: "to", minWidth: 100},
    {name: "Bridge", key: "bridge", minWidth: 160},
    {name: "Aggregator", key: "bridge", minWidth: 120},
    {name: "Return Tokens", key: "expectedReturn", minWidth: 180},
    {name: "Expected Profit", key: "expectedProfit", minWidth: 180},
    {name: "Fee", key: "fee", minWidth: 120},
    {name: "Value", key: "fee", minWidth: 120},
    {name: "Bribe", key: "bribe", minWidth: 120},
    {name: "Start 🕒", key: "timeStart", minWidth: 120},
    {name: "Start 🧱", key: "blockStart", minWidth: 120},
    {name: "Submit 🕒", key: "timeSubmitted", minWidth: 120},
    {name: "Create 🕒", key: "timeCreated", minWidth: 120},
    {name: "Submit 🧱", key: "blockSubmitted", minWidth: 120},
    {name: "Target 🧱", key: "blockTarget", minWidth: 120},
    {name: "Confirm 🕒", key: "timeCreated", minWidth: 120},
    {name: "Confirmed 🧱", key: "blockConfirmed", minWidth: 120},
    {name: "Status", key: "status", minWidth: 80},
    {name: "Duration", key: "duration", minWidth: 100},
    {name: "Error", key: "error", minWidth: 140},
    {name: "Provider", key: "provider", minWidth: 100},
    {name: "Pos", key: "txPos", minWidth: 50},
    {name: "Trigger", key: "initiation", minWidth: 100},
    {name: "Tenderly", key: "tenderly", minWidth: 100},
    {name: "JSON", key: "JSON", minWidth: 100},
]

/**
 *
 */
type TransactionsTableProps = {
    tokens: DBTokens
    trades: Trades
    textFilter: string
    filterData: TransactionsFilterData
}
export default memo(function TransactionsTable({
                                                   tokens: dbTokens,
                                                   trades: tradesData,
                                                   textFilter,
                                                   filterData,
                                               }: TransactionsTableProps) {
    const trades = Object.values(tradesData)
    const tokens = mergeDbTokensWithStaticTokens(dbTokens)

    /**
     * Filtering
     */
    const filteredTrades = useMemo(() => {
        return trades.filter((trade) => {
            const {txs} = trade
            const text = textFilter.toLowerCase()

            let textCondition = textFilter === ""
            let successCondition = filterData.success === "none"
            let failedCondition = filterData.failed === "none"
            let replacedCondition = filterData.replaced === "none"
            let unknownCondition = filterData.unknown === "none"
            let manualCondition = filterData.manual === "none"

            if (!txs) return false

            for (const tx of Object.values(txs)) {
                if (!textCondition) {
                    textCondition =
                        tx.txType?.toLowerCase().includes(text) ||
                        (tx as any).network?.toLowerCase().includes(text) ||  // legacy txs
                        tx.networkBuy?.toLowerCase().includes(text) ||
                        tx.networkSell?.toLowerCase().includes(text) ||
                        (tx as any).networkName?.toLowerCase().includes(text) || // legacy txs
                        tx.errMessage?.toLowerCase().includes(text) ||
                        tx.txHash?.toLowerCase().includes(text) ||
                        tx.tradeId?.includes(text) ||
                        tx.tokenName?.toLowerCase().includes(text) ||
                        (tx as any).bridgeName?.toLowerCase().includes(text) ||
                        (tx as any).dexName?.toLowerCase().includes(text)
                }

                if (!successCondition) {
                    successCondition =
                        (filterData.success === "true" && !!tx.success) ||
                        (filterData.success === "false" && !tx.success)
                }
                if (!failedCondition) {
                    failedCondition =
                        (filterData.failed === "true" && !tx.success) ||
                        (filterData.failed === "false" && !!tx.success)
                }
                if (!replacedCondition) {
                    replacedCondition =
                        (filterData.replaced === "true" && !!tx.replaced) ||
                        (filterData.replaced === "false" && !tx.replaced)
                }
                if (!unknownCondition) {
                    const status = !!tx.success ? "success" : "failed"
                    const isUnknown = isUnknownStatus(tx.errMessage || "", status)
                    unknownCondition =
                        (filterData.unknown === "true" && !!isUnknown) ||
                        (filterData.unknown === "false" && !isUnknown)
                }

                if (!manualCondition) {
                    manualCondition =
                        (filterData.manual === "true" && !!tx?.isManual) ||
                        (filterData.manual === "false" && !tx?.isManual)
                }
            }

            return (
                textCondition &&
                successCondition &&
                failedCondition &&
                replacedCondition &&
                unknownCondition &&
                manualCondition
            )
        })
    }, [trades, textFilter])

    /**
     * Sorting
     */
    const sortedTrades = filteredTrades.sort((a, b) => {
        return +b.id - +a.id
    })

    const data: TableRow[] = []

    for (const trade of sortedTrades) {
        if (!trade.txs || !Object.values(trade.txs).length) {
            continue
        }

        const buyTxs = Object.values(trade.txs).filter(t => t.txType === TxType.Bundle || t.txType === TxType.Swap || t.txType === TxType.Bridge || t.txType === TxType.ApproveBridge || t.txType === TxType.ApproveSwap).sort((a, b) => a.nonce - b.nonce)
        const sellTxs = Object.values(trade.txs).filter(t => t.txType === TxType.BundleSell || t.txType === TxType.Sell || t.txType === TxType.Claim || t.txType === TxType.Transfer || t.txType === TxType.ApproveSell).sort((a, b) => a.nonce - b.nonce)

        const buyTx = buyTxs[0]
        const firstTx = Object.values(trade.txs)[0]
        const mainTx = buyTx ?? firstTx
        const isManual = !!mainTx.isManual

        const tradeTxs = [...buyTxs, ...sellTxs].map((txData) => {
            // @ts-ignore
            const t = (tokens[txData?.tokenName]?.networkData ?? {})[txData?.networkName]?.decimals ?? 0

            const initiation = txData.isManual ? "Manual" : "Automation"
            const status = txData.success === undefined ? "N/A" : txData.success ? "Success" : "Failed"

            const isSell = txData.txType === TxType.BundleSell || txData.txType === TxType.ApproveSell || txData.txType === TxType.Sell || txData.txType === TxType.Claim || txData.txType === TxType.Transfer
            const buyNetwork = txData.networkBuy ?? (mainTx as any).network ?? (txData as any).networkName
            const sellNetwork = (mainTx as any).networkTo ?? mainTx.networkSell ?? "N/A"
            const txNetwork = isSell ? sellNetwork : buyNetwork
            const txNetworkTo = isSell ? buyNetwork : sellNetwork

            const expectedReturn =
                !!(txData)?.dstTokenAmount && (txData)?.dstTokenAmount !== "N/A"
                    ? `${BigNumber((txData).dstTokenAmount)
                        .div(10 ** t)
                        .toFormat(2)
                        .toString()}`
                    : "N/A"

            const expectedProfit =
                !!(txData)?.expectedProfit && (txData)?.expectedProfit !== "N/A"
                    ? `${BigNumber((txData).expectedProfit)
                        .toFormat(8)
                        .toString()}`
                    : "N/A"

            return [
                <SideCell type={txData.txType}/>,
                <TxHashCell networkName={txNetwork} txHash={txData.txHash}/>,
                <SenderCell networkName={txNetwork} sender={txData.sender}/>,
                <TypeCell type={txData.txType}/>,
                <TokenCell tokenName={txData.tokenName} networkName={txNetwork} tokenAddress={txData.tokenAddress}/>,
                <NetworkCell networkName={txNetwork}/>,
                txData.txType === TxType.Bridge || txData.txType === TxType.Bundle ?
                    <ToCell to={txNetworkTo}/> : <></>,
                <BridgeCell networkName={txNetwork} contractAddress={txData.bridgeApproveTarget}
                            bridgeName={txData.bridgeName}/>,
                <AggregatorCell name={txData.dexName ?? ""}/>,
                !!expectedReturn ? (
                    <ExpectedReturnCell expectedReturn={expectedReturn} tokenName={isSell ? "WETH" : txData.tokenName}/>
                ) : (
                    <></>
                ),
                !!expectedProfit ? (
                    <ExpectedProfitCell expectedProfit={expectedProfit} ethPrice={txData.ethPrice}/>
                ) : (
                    <></>
                ),
                <FeeCell network={txNetwork} fee={txData.fee} nativePrice={txData.nativePrice}/>,
                <ValueCell network={txNetwork} value={txData.value} nativePrice={txData.nativePrice}/>,
                <ValueCell network={txNetwork} value={txData.bribeWei} nativePrice={txData.nativePrice} wei={true}/>,
                <TimeCell time={txData.timeStart}/>,
                <BlockCell
                    networkName={txNetwork}
                    blockTime={txData.blockStartTime}
                    blockNumber={!!txData.blockStart ? `${txData.blockStart}` : "N/A"}
                />,
                <TimeCell time={txData.timeSent}/>,
                <TimeCell time={txData.timeCreated}/>,
                <BlockCell
                    networkName={txNetwork}
                    blockTime={txData.blockSubmittedTime}
                    blockNumber={!!txData.blockSubmitted ? `${txData.blockSubmitted}` : "N/A"}
                />,
                <BlockCell
                    networkName={txNetwork}
                    blockTime={txData.blockTargetTime}
                    blockNumber={txData.blockTarget ? `${txData.blockTarget}` : "N/A"}
                />,
                <TimeCell time={txData.timeConfirmed}/>,
                <BlockCell
                    networkName={txNetwork}
                    blockTime={txData.blockConfirmedTime}
                    blockNumber={txData.blockConfirmed ? `${txData.blockConfirmed}` : "N/A"}
                />,
                <StatusCell replaced={txData.replaced} status={status} error={txData.errMessage}/>,
                <DurationCell startTime={txData.timeSent} endTime={txData.timeConfirmed}/>,
                !!txData.errMessage ? <ErrorCell error={txData.errMessage}/> : <></>,
                <ProviderCell provider={txData.sendMethod ?? txData.provider ?? "public"}/>,
                <PosCell blockPos={txData.blockPos}/>,
                <TriggerCell initiation={initiation}/>,
                <TenderlyCell tx={txData}/>,
                <JSONCell title={`Transaction ${txData.txHash} JSON data`} data={txData}/>,
            ]
        })

        const date = timestampToDate(trade.id)
        const {msg: profitMsg, profit, fee, color: headerColor} = getTradeProfitMsg(trade, isManual)
        const headerTitle = `[${date}] ${trade.id}: ${profitMsg}`

        data.push({
            title: headerTitle,
            profit: profit ?? 0,
            fee,
            time: trade.completionTime,
            color: headerColor,
            action: {
                handler: () => showJSONPopup(`Trade ${trade.id} JSON data`, trade),
                name: "JSON",
            },
        })
        data.push(...tradeTxs)
    }

    const headerRows = useMemo(() => data.filter(r => !!(r as HeaderRow).title) as HeaderRow[], [sortedTrades])
    const {last24h, last7d, last30d} = useMemo(() => filterHeaderRowsByTimeRange(headerRows), [headerRows])

    const profit24H = useMemo(() => last24h.reduce((acc, cur) => acc + cur.profit, 0).toFixed(2), [last24h])
    const profit7D = useMemo(() => last7d.reduce((acc, cur) => acc + cur.profit, 0).toFixed(2), [last7d])
    const profit30D = useMemo(() => last30d.reduce((acc, cur) => acc + cur.profit, 0).toFixed(2), [last30d])

    const fee24H = useMemo(() => last24h.reduce((acc, cur) => acc + cur.fee, 0).toFixed(2), [last24h])
    const fee7D = useMemo(() => last7d.reduce((acc, cur) => acc + cur.fee, 0).toFixed(2), [last7d])
    const fee30D = useMemo(() => last30d.reduce((acc, cur) => acc + cur.fee, 0).toFixed(2), [last30d])

    const estimateRowHeight = useCallback(() => 71, [])

    return (
        <Stack gap={2}>
            <div style={{display: "flex"}}>
                <Badge
                    bg="light" text="dark" className="h1"
                    style={{fontSize: "0.8em", padding: "9px", marginRight: "12px"}}>
                    {`Showing ${sortedTrades.length} / ${sortedTrades.length} entries`}
                </Badge>
                <Badge
                    bg="light" text="dark" className="h1"
                    style={{fontSize: "0.8em", padding: "9px", marginRight: "12px"}}>
                    {`Trades Count 24h: ${last24h.length} / 7d: ${last7d.length} / 30d: ${last30d.length}`}
                </Badge>
                <Badge
                    bg="light" text="dark" className="h1"
                    style={{fontSize: "0.8em", padding: "9px", marginRight: "12px"}}>
                    {`Approx Profit 24h: ${profit24H}$ / 7d: ${profit7D}$ / 30d: ${profit30D}$`}
                </Badge>
                <Badge
                    bg="light" text="dark" className="h1"
                    style={{fontSize: "0.8em", padding: "9px", marginRight: "12px"}}>
                    {`Approx Fee 24h: ${fee24H}$ / 7d: ${fee7D}$ / 30d: ${fee30D}$`}
                </Badge>
            </div>
            <VirtualTable
                columns={columns}
                data={data}
                style={{fontFamily: "monospace"}}
                estimateRowHeight={estimateRowHeight}
            />
        </Stack>
    )
})

/**
 *
 */
type SenderCellProps = {
    networkName: string
    sender: string
}
const SenderCell = memo(function SenderCell({networkName, sender}: SenderCellProps) {
    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                size="sm"
                variant="light"
                style={{fontSize: "0.8em"}}
                onClick={() => {
                    window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/address/${sender}`, "_blank")
                }}
            >
                {!!sender ? sender.slice(0, 6) + ".." + sender.slice(-4) : ""}
            </Button>
        </div>
    )
})

/**
 *
 */
type TokenCellProps = {
    tokenName: string
    networkName: NETWORK
    tokenAddress?: string
}
const TokenCell = memo(function TokenCell({tokenName, tokenAddress, networkName}: TokenCellProps) {
    return (
        <div className="d-grid" style={{height: "100%"}}>
            {tokenAddress ? <Button
                size="sm"
                variant="light"
                onClick={() => {
                    window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/address/${tokenAddress}`, "_blank")
                }}
            >
                <span style={{display: "block"}}>{tokenName}</span>
            </Button> : <Badge bg="light" text="dark" className="noBg">{tokenName}</Badge>}
        </div>
    )
})

/**
 *
 */
type NetworkCellProps = {
    networkName: string
}
const NetworkCell = memo(function FromCell({networkName}: NetworkCellProps) {
    return (
        <Badge bg="light" text="dark" className="noBg">
            {networkName}
        </Badge>
    )
})

/**
 *
 */
type SideCellProps = {
    type: TxType
}
const SideCell = memo(function SideCell({type}: SideCellProps) {
    const isSell = type === TxType.BundleSell || type === TxType.ApproveSell || type === TxType.Sell || type === TxType.Claim || type === TxType.Transfer
    const style = {width: "20px", height: "20px"}
    return <Badge bg="light" text="dark" className="noBg">
        {isSell ? <ArrowLeft style={{...style, color: "darkmagenta"}}/> :
            <ArrowRight style={{...style, color: "darkcyan"}}/>}
    </Badge>
})

/**
 *
 */
type TypeCellProps = {
    type: TxType
}
const TypeCell = memo(function TypeCell({type}: TypeCellProps) {
    return <Badge bg="light" text="dark" className="noBg">{type}</Badge>
})

/**
 *
 */
type AggregatorCellProps = {
    name: string
}
const AggregatorCell = memo(function AggregatorCell({name}: AggregatorCellProps) {
    const capName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
    return <Badge bg="light" text="dark" className="noBg">{capName}</Badge>
})

/**
 *
 */
type BridgeCellProps = {
    networkName: string
    bridgeName?: string
    contractAddress?: string
}
const BridgeCell = memo(function BridgeCell({networkName, bridgeName, contractAddress}: BridgeCellProps) {
    const height = contractAddress ? "100%" : "auto"
    return (
        <div className="d-grid" style={{height}}>
            {contractAddress ? <Button
                size="sm"
                variant="light"
                onClick={() => {
                    window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/address/${contractAddress}`, "_blank")
                }}
            >
                <span style={{display: "block"}}>{bridgeName}</span>
            </Button> : <Badge bg="light" text="dark" className="noBg">{bridgeName}</Badge>}
        </div>
    )
})

/**
 *
 */
type ToCellProps = {
    to: string
}
const ToCell = memo(function ToCell({to}: ToCellProps) {
    return (
        <Badge bg="light" text="dark" className="noBg">
            {to}
        </Badge>
    )
})

/**
 *
 */
type ExpectedReturnCellProps = {
    expectedReturn: string
    tokenName?: string
}
const ExpectedReturnCell = memo(function ExpectedReturnCell({expectedReturn, tokenName}: ExpectedReturnCellProps) {
    return (
        <Badge bg="light" text="dark" className="noBg">
            {expectedReturn === "NaN" ? "N/A" : expectedReturn} {tokenName ?? ""}
        </Badge>
    )
})

/**
 *
 */
type ExpectedProfitCellProps = {
    expectedProfit: string
    ethPrice?: number
}
const ExpectedProfitCell = memo(function ExpectedProfitCell({expectedProfit, ethPrice}: ExpectedProfitCellProps) {
    const profit = !expectedProfit || expectedProfit === "0" ? "0" : BigNumber(expectedProfit).toFormat(4).toString()
    const profitUSD = ethPrice ? BigNumber(profit).multipliedBy(ethPrice).toFormat(4).toString() : undefined
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Badge bg="light" text="dark" className="noBg">{profit} ETH</Badge>
            {profitUSD ? <Badge bg="light" text="dark" className="noBg">{`${profitUSD} $`}</Badge> : <></>}
        </div>
    )
})

/**
 *
 */
type TimeProps = {
    time?: number
}
const TimeCell = memo(function TimeCell({time}: TimeProps) {
    let content = !!time && time > 0 ? formatTime(time, true) : "N/A"
    return <Badge bg="light" text="dark" className="noBg">{content}</Badge>
})

const formatTime = (timestamp: number | string, ms = false): string => {
    timestamp = toMilliseconds(Number(timestamp))

    const date = new Date(+timestamp);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const milliseconds = date.getUTCMilliseconds();

    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = seconds.toString().padStart(2, '0');
    const millisecondsStr = milliseconds.toString().padStart(3, '0');

    const base = `${hoursStr}:${minutesStr}:${secondsStr}`;
    return ms ? `${base}:${millisecondsStr}` : base
};

/**
 *
 */
type BlockCellProps = {
    networkName: string
    blockTime?: number
    blockNumber: string
}
const BlockCell = memo(function BlockCell({
                                              networkName,
                                              blockTime,
                                              blockNumber,
                                          }: BlockCellProps) {
    return !blockNumber || blockNumber === "N/A" ? (
        <Badge bg="light" text="dark" className="noBg">
            {blockNumber || "N/A"}
        </Badge>
    ) : (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                size="sm"
                variant="light"
                style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center'}}
                onClick={() => {
                    window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/block/${blockNumber}`, "_blank")
                }}
            >
                <span style={{fontSize: "0.9em"}}>{blockNumber.toLocaleString()}</span>
                {!!blockTime && blockTime > 0 ?
                    <Badge bg="light" text="dark" className="noBg"
                           style={{marginTop: "8px", fontSize: "0.9em"}}>{`${formatTime(blockTime)}`}</Badge> : <></>}
            </Button>
        </div>
    )
})

/**
 *
 */
type StatusCellProps = {
    status: string
    error?: string
    replaced?: boolean
}
const StatusCell = memo(function StatusCell({status, replaced, error}: StatusCellProps) {
    let colorClass: string | undefined
    if (status.toLowerCase() === "success") {
        colorClass = "success-imp"
    }
    if (status.toLowerCase() === "failed") {
        colorClass = "error-imp"
    }
    if (replaced) {
        colorClass = "warning-imp"
    }

    // check for unknown or coleasce error
    if (isUnknownStatus(error || "", status)) {
        colorClass = "warning-imp"
        status = "Unknown"
    }

    const className = `noBg ${colorClass || ""}`

    return (
        <Badge bg="light" text="dark" className={className}>
            {replaced ? "Replaced" : status}
        </Badge>
    )
})

/**
 *
 */
type DurationCellProps = {
    startTime?: number
    endTime?: number
}
const DurationCell = memo(function DurationCell({startTime, endTime}: DurationCellProps) {
    const duration =
        !!startTime && startTime > 0 && !!endTime && endTime > 0 ? formatDuration(startTime, endTime) : "N/A"
    return (
        <Badge bg="light" text="dark" className="noBg">
            {duration}
        </Badge>
    )
})

/**
 *
 */
type ErrorCellProps = {
    error?: string
}
const ErrorCell = memo(function ErrorCell({error}: ErrorCellProps) {
    const showFullError = (err: string) => Popup.popupMessage("Error", err)
    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                size="sm"
                variant="light"
                style={{fontSize: "0.8em", cursor: "pointer"}}
                className="text-truncate"
                onClick={() => showFullError(error || "")}
            >
                {error || ""}
            </Button>
        </div>
    )
})

/**
 *
 */
type ProviderCellProps = {
    provider: string
}
const ProviderCell = memo(function ProviderCell({provider}: ProviderCellProps) {
    return <Badge bg="light" text="dark" className="noBg">{provider}</Badge>
})

/**
 *
 */
type PosCellProps = {
    blockPos?: number
}
const PosCell = memo(function PosCell({blockPos}: PosCellProps) {
    return (
        <Badge bg="light" text="dark" className="noBg">
            {(!blockPos && blockPos !== 0) || (blockPos as any) === "N/A" ? "N/A" : blockPos}
        </Badge>
    )
})

/**
 *
 */
type FeeCellProps = {
    network: NETWORK
    fee?: string
    nativePrice?: number
}
const FeeCell = memo(function FeeCell({network, fee, nativePrice}: FeeCellProps) {
    const feeNum = !!fee ? BigNumber(fee) : "N/A"

    let feeStr = "N/A"
    if (feeNum !== "N/A") {
        feeStr = `${feeNum.toFormat(4).toString()}`
    }
    const feeUSD = feeStr !== "N/A" && nativePrice ? BigNumber(feeNum).multipliedBy(nativePrice).toFormat(4).toString() : undefined
    const feeTicker = NetworkToTicker[network]

    return <div style={{display: "flex", flexDirection: "column"}}>
        <Badge bg="light" text="dark" className="noBg">{feeStr} {feeStr !== "N/A" ? feeTicker : ""}</Badge>
        {feeUSD ? <Badge bg="light" text="dark" className="noBg">{`${feeUSD} $`}</Badge> : <></>}
    </div>
})

/**
 *
 */
type ValueCellProps = {
    network: NETWORK
    value?: string | number
    nativePrice?: number
    wei?: boolean
}
const ValueCell = memo(function ValueCell({network, value, nativePrice, wei}: ValueCellProps) {
    let valNum = (!!value || value === "0") ? BigNumber(value) : "N/A"

    if (!!valNum && valNum !== "N/A" && typeof valNum !== "string" && wei) {
        valNum = valNum.div(1e18)
    }

    let valStr = "N/A"
    if (valNum !== "N/A" && typeof valNum !== "string") {
        valStr = `${valNum.toFormat(4).toString()}`
    }

    const valueUSD = valStr !== "N/A" && nativePrice ? BigNumber(valNum).multipliedBy(nativePrice).toFormat(4).toString() : undefined
    const valueTicker = NetworkToTicker[network]

    return <div style={{display: "flex", flexDirection: "column"}}>
        <Badge bg="light" text="dark" className="noBg">{valStr} {valStr !== "N/A" ? valueTicker : ""}</Badge>
        {valueUSD ? <Badge bg="light" text="dark" className="noBg">{`${valueUSD} $`}</Badge> : <></>}
    </div>
})

/**
 *
 */
type TriggerCellProps = {
    initiation: string
}
const TriggerCell = memo(function TriggerCell({initiation}: TriggerCellProps) {
    return (
        <Badge bg="light" text="dark" className="noBg">
            {initiation}
        </Badge>
    )
})

/**
 *
 */
type TxHashCellProps = {
    networkName: string
    txHash?: string | number
}
const TxHashCell = memo(function TxHashCell({networkName, txHash}: TxHashCellProps) {
    const baseHash = `${!!txHash ? txHash : Date.now()}`
    const splitted = baseHash.split(":")
    const hash = baseHash.includes(":") ? splitted[splitted.length - 1] : baseHash

    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                size="sm"
                variant="light"
                style={{fontSize: "0.8em"}}
                onClick={() => {
                    window.open(`${NETWORK_EXPLORER_ADDRESS[networkName as NETWORK]}/tx/${hash}`, "_blank")
                }}
            >
                {hash.slice(0, 6) + ".." + hash.slice(-4)}
            </Button>
        </div>
    )
})

/**
 *
 */
type JSONCellProps = {
    title: string
    data: any
}
const JSONCell = memo(function JSONCell({title, data}: JSONCellProps) {
    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                size="sm"
                variant="light"
                style={{fontSize: "0.8em", cursor: "pointer"}}
                onClick={() => showJSONPopup(title, data)}
            >
                JSON
            </Button>
        </div>
    )
})

/**
 *
 */
type TenderlyCellProps = {
    tx: DBTX
}
const TenderlyCell = memo(function TenderlyCell({tx}: TenderlyCellProps) {
    const isSell = tx.txType === TxType.BundleSell || tx.txType === TxType.ApproveSell || tx.txType === TxType.Sell || tx.txType === TxType.Claim || tx.txType === TxType.Transfer
    const network = isSell ? tx.networkSell : tx.networkBuy

    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                size="sm"
                variant="light"
                style={{fontSize: "0.8em", cursor: "pointer"}}
                onClick={() => {
                    if (!tx.inputData) {
                        return
                    }

                    showInputDataPopup(tx.inputData)

                    let networkId = 1
                    switch (network ?? (tx as any).networkName) {
                        case NETWORK.ARBITRUM:
                            networkId = 42161
                            break
                        case NETWORK.BINANCE:
                            networkId = 56
                            break
                        case NETWORK.ETHEREUM:
                            networkId = 1
                            break
                        case NETWORK.POLYGON:
                            networkId = 137
                            break
                        case NETWORK.BASE:
                            networkId = 8453
                            break
                        case NETWORK.OPTIMISM:
                            networkId = 10
                            break
                        case NETWORK.GNOSIS:
                            networkId = 100
                            break
                        case NETWORK.SNOWTRACE:
                            networkId = 43114
                            break
                    }

                    const gasPrice = getValidGasPrice(tx) || "0"

                    const tenderlyUrl = `https://dashboard.tenderly.co/velocity/defi/simulator/new?block=${tx.blockConfirmed}&blockIndex=${tx.blockPos}&from=${tx.sender}&gas=${tx.gasLimit}&gasPrice=${gasPrice}&value=${tx.value}&contractAddress=${tx.contractAddress}&network=${networkId}`

                    window.open(tenderlyUrl, "_blank")
                }}
            >
                {tx.inputData ? "Tenderly" : "N/A"}
            </Button>
        </div>
    )
})

const isNumericAndNotZero = (value: string | undefined): boolean => {
    return value !== undefined && value !== "N/A" && value !== "0" && !isNaN(Number(value))
}

const getValidGasPrice = (tx: DBTX): string | undefined => {
    if (isNumericAndNotZero(tx.maxFeePerGas)) {
        return tx.maxFeePerGas
    } else if (isNumericAndNotZero(tx.effectiveGasPrice)) {
        return tx.effectiveGasPrice
    } else if (isNumericAndNotZero(tx.gasPrice)) {
        return tx.gasPrice
    }
    return undefined
}

const timestampToDate = (timestamp: string): string => {
    const date = new Date(+timestamp)

    const day = String(date.getUTCDate()).padStart(2, "0")
    const month = String(date.getUTCMonth() + 1).padStart(2, "0") // Month is 0-indexed
    const year = date.getUTCFullYear()
    const hours = String(date.getUTCHours()).padStart(2, "0")
    const minutes = String(date.getUTCMinutes()).padStart(2, "0")
    const seconds = String(date.getUTCSeconds()).padStart(2, "0")
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0")

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}.${milliseconds}`
}

const getTradeProfitMsg = (trade: Trade, isManual: boolean): {
    msg: string, profit?: number, fee: number,
    color: "#28a745" | "#6c757d" | "#900916" | "#dc3545"
} => {
    if (isManual) return {msg: "MANUAL", fee: 0, color: "#6c757d"}

    const txs = Object.values(trade.txs)
    if (txs.some(t => t.success === false)) {
        const {feeStr, totalFeeInUSD} = getTradeFeeMsg(txs)
        return {msg: `FAIL / ${feeStr}`, fee: totalFeeInUSD.toNumber(), color: "#900916"}
    }
    if (txs.some(t => t.sendBack)) {
        return {msg: "SENT BACK", fee: 0, color: "#900916"}
    }

    const bundle = txs.find(t => t.txType === TxType.Bundle)
    const swap = txs.find(t => t.txType === TxType.Swap)
    const sell = txs.find(t => t.txType === TxType.Sell || t.txType === TxType.BundleSell)

    if (!!trade.sellFailed) {
        const {feeStr, totalFeeInUSD} = getTradeFeeMsg(txs)
        return {msg: `SELL FAILED / ${feeStr}`, fee: totalFeeInUSD.toNumber(), color: "#900916"}
    }

    if (!sell) {
        if (Date.now() - txs.find(t => !!t.timeConfirmed)?.timeConfirmed! > 24 * 60 * 60 * 1000) {
            const {feeStr, totalFeeInUSD} = getTradeFeeMsg(txs)
            return {msg: `SELL FAILED / ${feeStr}`, fee: totalFeeInUSD.toNumber(), color: "#900916"}
        }
        return {msg: "PENDING", fee: 0, color: "#6c757d"}
    }

    const amountIn = (bundle?.srcTokenAmount ?? swap?.srcTokenAmount) ?? "0"
    const amountOut = sell.dstTokenAmount ?? "0"

    const profit = BigNumber(amountOut).minus(BigNumber(amountIn))
    const ethUSD = txs.find(t => (t.ethPrice ?? 0) > 0)?.ethPrice!
    const {totalFeeInETH, feeStr, totalFeeInUSD} = getTradeFeeMsg(txs)

    const profitMinusFee = profit.minus(totalFeeInETH)
    const profitETHStr = profitMinusFee.toFormat(4).toString()
    const profitUSD = profitMinusFee.multipliedBy(ethUSD)
    const profitUSDStr = profitUSD.toFormat(2).toString()
    const plusSign = profitETHStr.startsWith("-") ? "" : "+"
    const profitStr = `${plusSign}${profitETHStr} WETH (${profitUSDStr}$)`

    const color = profitETHStr.startsWith("-") ? "#dc3545" : "#28a745"
    return {msg: `${profitStr} / ${feeStr}`, fee: totalFeeInUSD.toNumber(), profit: profitUSD.toNumber(), color}
}

const getTradeFeeMsg = (txs: DBTX[]) => {
    const buyTxs = txs.filter(t => t.txType === TxType.Bundle || t.txType === TxType.Bridge || t.txType === TxType.ApproveBridge || t.txType === TxType.ApproveSwap)
    const buyFee = buyTxs.reduce((acc, cur) => acc.plus(BigNumber(cur.fee ?? "0")), BigNumber(0))
    const sellTxs = txs.filter(t => t.txType === TxType.BundleSell || t.txType === TxType.Sell || t.txType === TxType.Claim || t.txType === TxType.ApproveSell || t.txType === TxType.Transfer)
    const sellFee = sellTxs.reduce((acc, cur) => acc.plus(BigNumber(cur.fee ?? "0")), BigNumber(0))

    const ethUSD = txs.find(t => (t.ethPrice ?? 0) > 0)?.ethPrice!
    const isBuyETH = buyTxs.find(t => !!t.networkBuy)?.networkBuy === NETWORK.ETHEREUM
    const isSellETH = sellTxs.find(t => !!t.networkSell)?.networkSell === NETWORK.ETHEREUM

    // include value into estimation only when it's not ETH or when it's failed when Claim were there
    const buyValue = isBuyETH && (!buyTxs.find(t => t.txType === TxType.Claim) || buyTxs.every(t => t.success === true)) ? BigNumber(0) :
        buyTxs.reduce((acc, cur) => acc.plus(BigNumber(cur.value ?? "0")), BigNumber(0))
    const sellValue = isSellETH && (!sellTxs.find(t => t.txType === TxType.Claim) || sellTxs.every(t => t.success === true)) ? BigNumber(0) :
        sellTxs.reduce((acc, cur) => acc.plus(BigNumber(cur.value ?? "0")), BigNumber(0))

    let totalBuyFeeInETH = buyFee.plus(buyValue);
    let totalSellFeeInETH = sellFee.plus(sellValue);

    // Check if buyFee is not in ETH and convert it to ETH
    if (!buyFee.isZero() && !isBuyETH) {
        const buyNetworkUSD = buyTxs.find(t => (t.nativePrice ?? 0) > 0)?.nativePrice!;
        totalBuyFeeInETH = totalBuyFeeInETH.dividedBy(ethUSD).times(buyNetworkUSD);
    }

    // Check if sellFee is not in ETH and convert it to ETH
    if (!sellFee.isZero() && !isSellETH) {
        const sellNetworkUSD = sellTxs.find(t => (t.nativePrice ?? 0) > 0)?.nativePrice!;
        totalSellFeeInETH = totalSellFeeInETH.dividedBy(ethUSD).times(sellNetworkUSD);
    }

    // Sum the ETH values and calculate profit without fee
    const totalFeeInETH = totalBuyFeeInETH.plus(totalSellFeeInETH);
    const totalFeeInUSD = totalFeeInETH.multipliedBy(ethUSD)
    const feeETHStr = totalFeeInETH.toFormat(4).toString()
    const feeUSDStr = totalFeeInUSD.toFormat(2).toString()
    const feeStr = `Total Fee: ${feeETHStr} ETH (${feeUSDStr}$)`

    return {totalFeeInETH, totalFeeInUSD, feeStr}
}

const filterHeaderRowsByTimeRange = (rows: HeaderRow[]): {
    last24h: HeaderRow[],
    last7d: HeaderRow[],
    last30d: HeaderRow[]
} => {
    const now = Date.now();
    const last24h = rows.filter(row => now - row.time <= ONE_DAY);
    const last7d = rows.filter(row => now - row.time <= SEVEN_DAYS);
    const last30d = rows.filter(row => now - row.time <= THIRTY_DAYS);

    return {last24h, last7d, last30d};
};

const mergeDbTokensWithStaticTokens = (dbTokens: DBTokens): DBTokens => {
    return {...dbTokens, ...NETWORK_NATIVE_AND_WRAPPED_TOKENS}
}

const formatDuration = (startTime: number, endTime: number): string => {
    const duration = endTime - startTime // duration in milliseconds

    if (duration < 1000) {
        // less than a second
        return `${duration}ms`
    } else if (duration < 60000) {
        // less than a minute
        return `${(duration / 1000).toFixed(1)}s`
    } else if (duration < 3600000) {
        // less than an hour
        return `${(duration / 60000).toFixed(1)}m`
    } else {
        // hours or more
        return `${(duration / 3600000).toFixed(1)}h`
    }
}

const showJSONPopup = (title: string, data: any) => Popup.popupJSON(title, data)

const showInputDataPopup = (data: string) => Popup.popupJSON("Input data for Tenderly", data)

const isUnknownStatus = (error: string, status: string) =>
    !!error &&
    status.toLowerCase() !== "success" &&
    (error.includes("coleasce error") ||
        error.includes("already known") ||
        error.toLowerCase().includes("flashbot") ||
        error.toLowerCase().includes("flashbots") ||
        error.toLowerCase().includes("status for private tx"))
