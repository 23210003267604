import {NETWORK, NULL_ADDRESS} from "./defaults"
import {DBToken, DBTokens, WorkerIterationResult} from "./types"
import {BigNumber} from "bignumber.js"
import util from "util"

/**
 *
 */
export function isTokenValidForActiveState(tokenData: DBToken) {
    // const networksToTest = [NETWORK.ETHEREUM, NETWORK.BINANCE] as const
    // for (const networkName of networksToTest) {
    // 	const networkData = tokenData.networkData?.[networkName]
    // 	if (!networkData || networkData.address.length !== 42) return false
    // 	if (!networkData || networkData.address === NULL_ADDRESS) return false
    // }
    // return true

    /**
     * IF WE HAVE MULTIPLE NETWORKS
     */
    const requiredNumValid = Math.max(Object.entries(tokenData.networkData || {}).length, 2)
    let numValid = 0
    for (const [networkName, networkData] of Object.entries(tokenData.networkData || {})) {
        if (networkData.address.length !== 42) continue
        if (networkData.address === NULL_ADDRESS) continue
        // if (networkData.decimals === 0) continue
        numValid += 1
    }
    if (numValid >= requiredNumValid) return true
    return false
}

/**
 *
 */
export function filterActiveTokens(tokensData: DBTokens) {
    return Object.fromEntries(
        Object.entries(tokensData).filter(([tokenName, tokenData]) => {
            return tokenData.active
        })
    )
}

/**
 *
 */
export function applySlippageToBigIntForOutput(val: bigint, slippage: number): bigint {
    const fixedMultiplier = BigInt(Math.round(slippage * 100))
    const intermediateResult = val * fixedMultiplier
    return val - (intermediateResult + BigInt(100 / 2)) / BigInt(100)
}

/**
 *
 */
export function applySlippageToBigIntForInput(val: bigint, slippage: number): bigint {
    const fixedMultiplier = BigInt(Math.round(slippage * 100))
    const intermediateResult = val * fixedMultiplier
    return val + (intermediateResult + BigInt(100 / 2)) / BigInt(100)
}

export function getNetworkFromIterationResult(res: WorkerIterationResult): NETWORK {
    let highestReturnNetwork: NETWORK | undefined
    let highestReturnAmount: BigNumber | null = null

    Object.values(NETWORK).forEach((network) => {
        if (network in res.networkResults) {
            const networkReturnAmount = BigNumber(
                res.networkResults[network as keyof typeof res.networkResults].returnAmount
            )

            if (!highestReturnAmount || networkReturnAmount.comparedTo(highestReturnAmount) === 1) {
                highestReturnNetwork = network as NETWORK
                highestReturnAmount = networkReturnAmount
            }
        }
    })

    return highestReturnNetwork!
}

export function getNetworksFromIterationResult(res: WorkerIterationResult) {
    const {networkA, networkB} = res
    const networkBuy = Object.keys(res.networkResults)[0] as NETWORK
    const networkSell = networkA === networkBuy ? networkB : networkA
    return {networkBuy, networkSell}
}

/**
 * Network order does not matter.
 */
export function getBridgeNamesByTokenNetworkPairSortedByPriority(
    tokenBridge: DBToken["bridge"],
    networkA: NETWORK,
    networkB: NETWORK
) {
    const allTokenBridgeNames = Object.keys(tokenBridge ?? {})
    const bridgeNamesWithThisNetworkPairAndActive = allTokenBridgeNames.filter((bridgeName) => {
        const networksInBridge = tokenBridge?.[bridgeName]?.networks ?? []
        return (
            networksInBridge.includes(networkA) &&
            networksInBridge.includes(networkB) &&
            tokenBridge?.[bridgeName]?.automation === true
        )
    })
    const bridgeNamesSortedByPriority = bridgeNamesWithThisNetworkPairAndActive.sort((a, b) => {
        return ((tokenBridge || {})[a]?.priority ?? 0) - ((tokenBridge || {})[b]?.priority ?? 0)
    })
    return bridgeNamesSortedByPriority
}

/**
 * Returns slippage for this network pair. If not found, returns slippage for token. Type is "ts" if slippage is for token, "nps" if for network pair.
 * gValue is slippage for green network, rValue is slippage for red network.
 * @param slippage DBToken["slippage"]
 * @param networkPairSlippage DBToken["networkPairSlippage"]
 * @param greenNetwork network with bigger price
 * @param redNetwork network with lower price
 */
export function getTokenNetworkPairSlippage(
    slippage: DBToken["slippage"],
    networkPairSlippage: DBToken["networkPairSlippage"],
    greenNetwork: NETWORK,
    redNetwork: NETWORK
): { type: "ts" | "nps"; gValue: number | string; rValue: number | string } {
    if (!networkPairSlippage) return {type: "ts", gValue: slippage, rValue: slippage}
    const [n1, n2] = [greenNetwork, redNetwork].sort((a, b) => a.localeCompare(b)) as [NETWORK, NETWORK]
    const slippageForThisNetworkPair = networkPairSlippage[`${n1}_${n2}`]
    if (!slippageForThisNetworkPair) return {type: "ts", gValue: slippage, rValue: slippage}
    const slippageForThisNetworkPairForGreenNetwork = slippageForThisNetworkPair[greenNetwork]
    const slippageForThisNetworkPairForRedNetwork = slippageForThisNetworkPair[redNetwork]
    if (typeof slippageForThisNetworkPairForGreenNetwork !== "number")
        return {type: "ts", gValue: slippage, rValue: slippage}

    const gValue =
        slippageForThisNetworkPairForGreenNetwork === -1
            ? slippage
            : slippageForThisNetworkPairForGreenNetwork

    const rValue =
        slippageForThisNetworkPairForRedNetwork === -1
            ? slippage
            : slippageForThisNetworkPairForRedNetwork ?? slippage

    return {type: "nps", gValue, rValue}
}

/**
 * Network order does not matter.
 */
export function getTokenNetworkPairTrackIgnore(
    networkPairTrackIgnore: DBToken["networkPairTrackIgnore"],
    networkA: NETWORK,
    networkB: NETWORK,
    networkCheck: NETWORK
): boolean {
    if (!networkPairTrackIgnore) return false
    const [n1, n2] = [networkA, networkB].sort((a, b) => a.localeCompare(b)) as [NETWORK, NETWORK]
    const ignoreForThisNetworkPair = networkPairTrackIgnore[`${n1}_${n2}`]
    if (!ignoreForThisNetworkPair) return false
    const ignoreForThisNetworkPairForNetwork = ignoreForThisNetworkPair[networkCheck]
    return ignoreForThisNetworkPairForNetwork === true
}

/**
 *
 */
export function getTokenNetworkTrackIgnore(
    networkTrackIgnore: DBToken["networkTrackIgnore"],
    networkCheck: NETWORK
): boolean {
    if (!networkTrackIgnore) return false
    return networkTrackIgnore[networkCheck] === true
}

/**
 *
 */
export function getTokenNetworkTaxable(
    networkTokenTaxable: DBToken["networkTokenTaxable"],
    networkCheck: NETWORK
): boolean {
    if (!networkTokenTaxable) return false
    return networkTokenTaxable[networkCheck] === true
}

/**
 *
 */
export function getTokenNetworkPairPoopAutomation(
    networkPairPoopAutomation: DBToken["networkPairPoopAutomation"],
    networkA: NETWORK,
    networkB: NETWORK
): boolean | NETWORK {
    if (!networkPairPoopAutomation) return false
    const [n1, n2] = [networkA, networkB].sort((a, b) => a.localeCompare(b)) as [NETWORK, NETWORK]
    const value = networkPairPoopAutomation[`${n1}_${n2}`]
    return value === undefined || value === null ? false : value
}

/**
 *
 */
export function stringifyAny(data?: any): string {
    if (data === undefined || data === null) return ""

    const inspected = util.inspect(data, {depth: 5, maxArrayLength: null, maxStringLength: null});
    const singleLine = inspected.replace(/\n\s*/g, ' ');
    return JSON.stringify(singleLine);
}

export function toMilliseconds(timestamp: number): number {
    if (timestamp < 1_000_000_000_000) {
        return timestamp * 1000;
    }
    return timestamp;
}

export function sanitizeDecimalStr(decStr: string, maxDecimals = 18) {
    // Split the number string into the integer part and the decimal part
    let [integerPart, decimalPart] = decStr.split('.');

    // If there's no decimal part or if it's already within the limit, return the original string
    if (!decimalPart || decimalPart.length <= maxDecimals) {
        return decStr;
    }

    // Trim the decimal part to the maximum allowed length
    decimalPart = decimalPart.slice(0, maxDecimals);

    // Recombine the integer part and the trimmed decimal part
    return `${integerPart}.${decimalPart}`;
}

export function numberFromHex(hexString: string): string {
    if (!hexString.startsWith("0x")) hexString = "0x" + hexString;

    const decimalValue = BigInt(hexString);
    return decimalValue.toString(10);
}

export function txDecimalValue(value?: string): string {
    if (!value) return "0";

    let decimalValue;

    if (value.startsWith("0x")) {
        decimalValue = BigNumber(numberFromHex(value)).div(1e18)
    } else {
        // If the value length is greater than 10 and does not contain a decimal point,
        // assume it's in wei and needs to be converted to Ether by dividing by 1e18.
        if (value.length > 10 && !value.includes('.')) {
            decimalValue = BigNumber(value).div(1e18); // Convert Wei to Ether
        } else {
            return value
        }
    }

    return decimalValue.toPrecision(6).replace(/\.?0+$/, '');
}

export function txWeiValue(value?: string): string {
    if (!value) return "0";

    if (value.startsWith("0x")) {
        return BigNumber(numberFromHex(value)).integerValue(BigNumber.ROUND_DOWN).toFixed()
    }
    if (value.includes('.')) {
        return BigNumber(value).multipliedBy(1e18).integerValue(BigNumber.ROUND_DOWN).toFixed()
    }
    return value
}
